<template>
  <div>
    <radio
      :fieldAttributes="{ option: { 1: 'URL', 2: 'File Size' } }"
      v-model="radios"
    ></radio>
    <div v-show="radios == 1">
      <primaryTextfield
        type="text"
        :fieldAttrInput="{ name: 'url' }"
        label="URL"
        :value="showURL"
      ></primaryTextfield>
    </div>
    <div v-show="radios == 2">
      <primaryTextfield
        max-width="100"
        type="text"
        :fieldAttrInput="{ name: 'size' }"
        label="File size"
        v-model="size"
      ></primaryTextfield>
      <associatedOption
        type="select"
        :fieldAttributes="{ name: 'unit', associatedOption: units }"
        v-model="unit"
      ></associatedOption>
    </div>
  </div>
</template>
<script>
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
import associatedOption from "@/commonComponents/associatedOption.vue";
import radio from "@/commonComponents/radio.vue";
export default {
  components: {
    primaryTextfield,
    associatedOption,
    radio,
  },
  props: ["result", "field", "fieldAttributes","value"],
  data() {
    return {
      url: "http://acctopus.com/docs/download.php",
      units: ["Byte", "KB", "MB"],
    };
  },
  methods: {},
  computed: {
    showURL: function () {
      return this.url + "?" + "size=" + this.size + "&unit=" + this.unit;
    },
    size: {
      get() {
        var fileSize = 1;
        let givenValue = this.result.valueLine.file_size;
        if (typeof givenValue != "undefined") {
          this.units.forEach(function (opValue) {
            if (givenValue.indexOf(opValue) > -1) {
              fileSize = givenValue.replace(opValue, "");
            }
          });
        }
        return fileSize;
      },
      set(newValue) {
        this.$emit("field-value-changed", "file_size", newValue + this.unit);
      },
    },
    unit: {
      get() {
        var unit = "MB";
        let givenValue = this.result.valueLine.file_size;
        if (typeof givenValue != "undefined") {
          this.units.forEach(function (opValue) {
            if (givenValue.indexOf(opValue) > -1) {
              unit = opValue;
            }
          });
        }
        return unit;
      },
      set(newValue) {
        this.$emit("field-value-changed", "file_size", this.size + newValue);
      },
    },
    radios: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>